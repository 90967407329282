<template>
  <GridRow class="four-teaser-row-with-ads">
    <div
      v-for="(teaser, index) in teasers"
      :key="teaser.id || index"
      class="one-fourth-teaser"
      :class="{ 'one-fourth-teaser--medium-r': index === 2 && adsEnabled && showAdPosition }"
    >
      <template v-if="index === 2 && adsEnabled && showAdPosition">
        <MediumRectangle desktop-id="sas_23175" mobile-id="sas_23366" />
      </template>
      <template v-else>
        <OpinionTeaser v-if="teaser.attributes.textType === 'Meinung'" :article="teaser" />
        <DefaultTeaser v-else :article="teaser" :lazy="lazy" />
      </template>
    </div>
  </GridRow>
</template>

<script>
import GridRow from '@/components/ui/grid/row'
import DefaultTeaser from '@/components/ui/teaser/default-teaser'
import OpinionTeaser from '@/components/ui/teaser/opinion-teaser'
import MediumRectangle from '@/components/ads/medium-rectangle'

export default {
  components: {
    GridRow,
    DefaultTeaser,
    OpinionTeaser,
    MediumRectangle,
  },
  props: {
    box: {
      type: Object,
      required: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    showAds: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    teasers() {
      if (!this.adsEnabled) return [...this.box.relationships.articles]
      const articles = [...this.box.relationships.articles]
      if (articles.length > 2) {
        const lastArticle = articles.pop()
        articles.splice(2, 0, lastArticle)
      }
      return articles
    },
    adsEnabled() {
      return this.$config.adsEnabled
    },
    noAdPositions() {
      return this.$store.getters['cope/noAdPositions']
    },
    showAdPosition() {
      return !(
        this.noAdPositions.map((element) => element.formatId).includes('23175') ||
        this.noAdPositions.map((element) => element.formatId).includes('23366')
      )
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';
.four-teaser-row-with-ads {
  @media all and (min-width: $screen-width-767) {
    padding: 0 14px 20px 14px;
  }

  @media all and (min-width: 1200px) {
    padding: 0 0 20px 0;
  }

  .one-fourth-teaser {
    border-bottom: 1px solid $color-light-grey;
    padding: 20px 0;

    @media all and (min-width: $screen-width-767) {
      padding: 0 14px;
      border-bottom: none;
      border-right: 1px solid $color-light-grey;
      flex-basis: calc(25% + 7px);
    }

    &:nth-child(1) {
      flex-basis: calc(25% - 7px);
      padding-left: 0;
    }

    &:nth-child(4) {
      flex-basis: calc(25% - 7px);
      padding-right: 0;
      border-right: none;
    }

    .default-teaser,
    .opinion-teaser {
      width: 100%;
    }

    @media all and (min-width: $screen-width-767) {
      .default-teaser__footer {
        padding-right: 0;
      }
    }

    &--medium-r {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;

      @media all and (min-width: $screen-width-767) {
        padding: 0;
      }
    }
  }
}
</style>
